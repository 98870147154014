.seated-event-venue-location {
  font-size: 24px;
  letter-spacing: -0.02em;
}

.seated-event-venue-cell {
  font-size: 24px;
  letter-spacing: -0.02em;
}

.seated-event-date-cell {
  font-size: 20px;
  letter-spacing: -0.02em;
  font-weight: 500;
  padding-bottom: 5px;
}

.seated-event-details-cell {
  padding-top: 5px;
  font-size: 20px;
  letter-spacing: -0.02em;
}

.seated-event-link1,
.seated-event-link1:visited,
.seated-event-link2,
.seated-event-link2:visited {
  border: 1.8px solid #000000;
  border-radius: 45px;
  letter-spacing: -0.02em;
}

a.seated-event-link1,
a.seated-event-link1:visited,
a.seated-event-link1:hover {
  font-size: 20px;
  letter-spacing: -0.02em;
  border-radius: 45px;
  margin: 4px 9px;
  transition: none;
}

.seated-event-link1:hover,
.seated-event-link2:hover {
  background: #026F42 !important;
  color: white !important;
  text-decoration: none;
  transition: none;
}

.seated-event-link1-sold-out,
.seated-event-link1-sold-out:visited {
  background: transparent !important;
  color: red !important;
  border: none !important;
  letter-spacing: -0.02em;
}

.seated-event-link1-sold-out:hover,
.seated-event-link1-sold-out:hover:visited {
  text-decoration: line-through;
  border: none !important;
  background: none !important;
  color: red !important;
  text-shadow: none !important;
  letter-spacing: -0.02em;
}

div.seated-event-row {
  padding: 20px 30px;
  transition: none;
}

div.seated-event-row:hover {
  background: #026F42;
  animation: none;
  color: white;
}

div.seated-follow-box {
  margin-top: 50px;
}


a.seated-follow-link,
a.seated-follow-link:visited,
a.seated-follow-link:hover {
  border: 1.8px solid #000000;
  border-radius: 45px;
  font-size: 20px;
  letter-spacing: -0.02em;
  margin: 20px 10px;
  transition: none;
}

a.seated-follow-link:hover {
  background: #026F42;
  animation: none;
}

@media (max-width:450px) {

  .seated-event-link-cells {
    padding: 5px 0;
  }

  .seated-event-venue-location {
    padding: 3px 0;
  }

  .seated-event-date-cell {
    padding-bottom: 8px;
  }


}