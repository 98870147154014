.App {
  text-align: center;
}

.menu {
  display: none;
}

.menu-nav {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 103;
}

.visible {
  display: flex;
}

nav>a {
  display: flex;
  justify-content: center;
  font-size: 14vh;
  text-decoration: none;
  color: black;
  letter-spacing: -0.02em;
  margin: 10px 0;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 10vw;
  margin: 0;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

nav>a:hover {
  color: white;
}

.scroll-container {
  padding-top: 115px;
}

.scroll-container-contact {
  padding-top: 1rem;
}

.banner {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.02em;
}

.menu-button {
  position: fixed;
  width: 80px;
  height: 80px;
  background: black;
  border: none;
  border-radius: 50%;
  left: 1rem;
  top: 1rem;
  z-index: 101;
  cursor: pointer;
}

.menu-button:hover {
  background: none;
  border: 3px solid black;
}

.menu-button:active {
  transform: scale(0.95);
}

.menu-button-active {
  background: white;
  box-shadow: 0 0 0 9999px #026F42;
}

.menu-button-active:hover {
  background: none;
  border: 3px solid black;
}

.video-button {
  background: white;
}


.music-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100vw;
}

.album-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.album-cover {
  width: 50%;
  background-size: cover;
}

.album-info {
  width: 50%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.album-title {
  padding: 1em 0 0 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  line-height: 30px;
  margin: 0 0 1em 0;
  letter-spacing: -0.02em;
}

.album-buttons {
  display: flex;
  padding-left: 30px;
}

.album-button {
  margin-right: 9px;
  border: 1.8px solid #000000;
  color: black;
  background-color: white;
  border-radius: 45px;
  font-size: 1.8vw;
  letter-spacing: -0.02em;
  padding: 5px 20px;
  cursor: pointer;
}

.album-button:hover {
  background: #026F42;
  color: white;
  border: 1.8px solid #026F42;

}

.track-list {
  width: 100%;
  padding-bottom: 30px;
}

.track {
  display: flex;
  padding: 2px 30px;
  justify-content: space-between;
  font-size: 1.5vw;
  letter-spacing: -0.02em;
}

.track-title {
  display: flex;
}

.home-image-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.home-image {
  position: absolute;
  width: 720px;
  height: auto;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.home-image:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.home-drag-area {
  width: 100vw;
  min-height: 100vh;
}

.arrow-icon {
  height: 15vw;
}

.video-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  z-index: 1;
  letter-spacing: -0.02em;
  mix-blend-mode: exclusion;
  color: #FFFFFF;
}

.hidden {
  display: none;
}

.mc-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 250px;
}

.mc-label {
  font-size: 8vw;
}

.mc-button {
  font-size: 8vw;
  border: 6px solid #000000;
  color: black;
  border-radius: 90px;
  background-color: white;
  letter-spacing: -0.02em;
  padding: 10px 90px;
  cursor: pointer;
  margin-bottom: 8vw;
}

.mc-button:hover {
  background: #026F42;
  border: 6px solid #026F42;
  color: white;
}

.mc-input {
  margin: 0 0 8vw 0;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 6px solid black;
  width: 90vw;
}

.mc-message {
  font-style: normal;
  font-weight: 400;
  font-size: 4vw;
  letter-spacing: -0.02em;
}

form input[type=text] {
  font-style: normal;
  font-weight: 400;
  font-size: 8vw;
  color: #026F42;
  letter-spacing: -0.02em;
  text-align: center;
}

.team-social-container {
  margin: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.social-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: 30px;
}

.team-member>p {
  font-size: 24px;
  margin: 3px 0;
  letter-spacing: -0.02em;
}

.team-member>a {
  font-size: 24px;
  color: black;
  text-decoration: none;
}

.team-member>a:hover {
  text-decoration: underline;
}

ul#social {
  margin: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

ul#social li {
  list-style: none;
  margin: 0 15px;
}

ul#social li a {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: none;
  border-radius: 50%;
  box-shadow: 0 0 0 1.8px black;
  font-size: 30px;
  color: black;
}

ul#social li a:hover {
  background-color: #026F42;
  box-shadow: 0 0 0 1.8px #026F42;
  color: white;
}


.select-container {
  position: fixed;
  right: 1rem;
  top: 1rem;
}

.select {
  background: black;
  position: fixed;
  color: white;
  border: 1px solid #ffffff;
  font-size: 18px;
  right: 0;
  top: 0;
  z-index: 100;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
}

@media (max-width: 935px) {
  ul#social {
    display: initial;
  }

  ul#social li {
    list-style: none;
    margin: 20px 0px;
  }
}

@media (max-width: 990px) {
  .track {
    font-size: 1.3vw;
  }

  .album-title {
    padding: 0.5em 0 0 30px;
    margin: 0 0 0.5em 0;
  }

}

@media (max-width:670px) {

  .home-image {
    width: 87vw;
  }

  .scroll-container {
    padding-top: 10px;
  }

  nav>a {
    font-size: 64px;
    margin: 20px 0;
  }

  .menu-nav {
    height: 75vh;
  }

  .banner {
    min-height: initial;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .home-banner {
    margin-top: 0;
    margin-bottom: 0;
  }

  .home-drag-area,
  .home-banner,
  .home-image-container {
    min-height: -webkit-fill-available;
  }

  .menu-button {
    width: 50px;
    height: 50px;
  }

  .album-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .album-cover {
    width: 100%;
    height: 100vw;
  }

  .album-info {
    width: 100%;
    aspect-ratio: 1/1;
  }

  .album-button {
    margin-bottom: 20px;
    font-size: 20px;
    padding: 5px 18px;
    margin-right: 0;
  }

  .album-buttons {
    width: 100%;
    padding: 0;
    justify-content: space-between;
  }

  .album-buttons-container {
    margin: 0 20px;
  }

  .album-title {
    font-size: 24px;
    padding: 20px 0 0 20px;
    margin: 0 0 20px 0;
  }

  .track {
    font-size: 20px;
    padding: 2px 20px;
  }

  ul#social li {
    margin: 0 2px;
  }

  ul#social {
    padding: 0 0 60px 0;
    display: flex;
  }

  ul#social li a {
    box-shadow: none;
  }

  .team-grid {
    width: 100%;
  }

  .team-social-container {
    margin: 20px;
    flex-direction: column-reverse;
    align-items: center;
  }


  .team-member>p,
  .team-member>a {
    font-size: 18px;
  }

  .mc-input {
    border-bottom: 3px solid black;
    width: 85vw;
  }

  .mc-button {
    border: 3px solid black;
  }

  .mc-container {
    margin-bottom: 40px;
  }

  .select {
    font-size: 12px;
  }

}