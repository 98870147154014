div.plyr__video-embed iframe {
  height: 200%;
  overflow: hidden;
}


div.plyr__video-embed {
  min-height: 100vh;
  padding-bottom: 0;
}

.plyr--full-ui input[type=range] {
  color: #026F42;
}

@media (max-width:670px) {

  div.plyr__video-embed {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

}